@import 'src/styles/imports';

.component {
  position: fixed;
  z-index: $z-index-panel;
  top: 0;
  right: -100%;
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  padding: 1.5em 2em;
  background-color: $color-grey-10;
  color: $color-white;
  align-items: center;
  transition: all .3s ease-in-out;
}

.active {
  right: 0;
}

.close {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  float: right;
  cursor: pointer;

  svg {
    width: 1em;
    height: 1em;

    path {
      fill: $color-white;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 2.125em;
  position: relative;
  display: inline-block;
  margin-bottom: .8em;
  font-family: $font-family-serif;
}

@include mobile {
  .title {
    padding-top: .25em;
  }
}

.divider {
  width: 100%;
  height: .5px;
  margin: 1em 0;
  background-color: $color-grey-12;
}

.menu-items {
  padding-bottom: 1em;

  li {
    .item-header {
      display: flex;
      padding: 0.8em 0;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;

      &.active .nib-icon {
        transform: rotate(180deg);
      }

      .nib-icon {
        transition: transform 0.3s ease-in-out;
        
        path {
          fill: $color-white;
          stroke: $color-white;
        }
      }

      a,
      span {
        font-size: 1.125em;
        font-weight: $font-weight-regular;
        flex-grow: 1;
      }
    }

    a {
      font-size: 1.125em;
      display: block;
      padding: .8em 0;
      border-bottom: 0;
      color: $color-white;

      &:hover {
        font-weight: $font-weight-bold;
      }
    }
  }

  .nested-menu {
    margin-left: 0.75em;
    list-style: none;

    li {
      padding-left: 1em;
      border-left: 1px solid $color-dark-navy-20;

      a,
      span {
        font-size: 1.125em;
        padding: 0.5em 0;
        font-weight: $font-weight-light;

        &:hover {
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}
